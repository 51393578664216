<template>
  <h3 class="heading-3">
    {{ i18n(translations.title) }}
  </h3>
  <RevCard>
    <div v-if="paymentErrorMessage" ref="errorMessageBlock" class="flex">
      <RevInfoBlock
        class="mx-20 mb-20 mt-24"
        dismissable
        :icon="IconHeartExploded"
        :title="paymentErrorMessage.title"
        variant="warning"
      >
        <div class="body-2 mt-8">
          <p>{{ paymentErrorMessage.description }}</p>
        </div>
      </RevInfoBlock>
    </div>
    <PaymentForm
      :base-price="totalPrice"
      :create-payment="refreshPayment"
      :payment-methods="paymentMethods || []"
      :resume-payment-id="resumePaymentId"
      @setup-error="handleSetupError"
      @submit-error="handleSubmitError"
      @submit-success="handleSubmitSuccess"
      @update:selected-method="handleMethodChange"
    />
  </RevCard>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed, ref } from 'vue'

import { type MonetaryAmount, paymentAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import {
  PaymentForm,
  type PaymentFormCreateFunctionOptions,
  type PaymentReadableMessage,
  type SetupErrorEvent,
  type SubmitErrorEvent,
  type SubmitSuccessData,
  toCheckoutPaymentCreateBody,
  usePaymentRedirection,
} from '@backmarket/nuxt-module-payment/form'
import { useMarketPaymentMethodsFor } from '@backmarket/nuxt-module-payment/methods'
import { RevCard } from '@ds/components/Card'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconHeartExploded } from '@ds/icons/IconHeartExploded'

import { CHECKOUT } from '~/scopes/checkout/routes-names'

import translations from './Payment.translations'

const props = defineProps<{
  policyId: string
  totalPrice: MonetaryAmount
}>()

const paymentErrorMessage = ref<PaymentReadableMessage | null>(null)
const errorMessageBlock = ref<HTMLInputElement | null>()

const route = useRoute()
const i18n = useI18n()

const resumePaymentId = computed(() => route.query.paymentId as string)

const { data: paymentMethods } = await useMarketPaymentMethodsFor({
  bagPrice: () => props.totalPrice,
  hasInsurance: true,
})

const proceedRedirection = usePaymentRedirection({
  paymentResultLocation: (paymentId) => ({
    name: CHECKOUT.PAYMENT_RESULT,
    params: { paymentId },
  }),
})

const refreshPayment = async (options: PaymentFormCreateFunctionOptions) => {
  const paymentResponse = await $httpFetch(paymentAPI.postRefresh, {
    pathParams: {
      policyId: props.policyId,
    },
    body: {
      ...toCheckoutPaymentCreateBody(options),
    },
  })

  return paymentResponse
}
const showErrorMessage = (message: PaymentReadableMessage) => {
  paymentErrorMessage.value = message
  errorMessageBlock.value?.scrollIntoView({ behavior: 'smooth' })
}

const handleSetupError = async ({ message }: SetupErrorEvent) => {
  showErrorMessage(message)
}

const handleSubmitError = async ({
  redirection,
  message,
}: SubmitErrorEvent) => {
  if (redirection) {
    proceedRedirection(redirection)

    return
  }
  if (message) showErrorMessage(message)
}

const handleSubmitSuccess = ({ redirection }: SubmitSuccessData) => {
  proceedRedirection(redirection)
}

const handleMethodChange = () => {
  paymentErrorMessage.value = null
}
</script>
