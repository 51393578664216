<template>
  <Layout :is-loading="pending || Boolean(error)">
    <template #default>
      <Checkout
        v-if="data"
        :policy-id="policyId"
        :total-price="data?.payment.total"
      />
    </template>

    <template #aside>
      <div class="space-y-24">
        <h2 class="heading-3">{{ i18n(translations.summaryTitle) }}</h2>

        <DueSummary v-if="data" :payment="data.payment" :policy="data.policy" />
      </div>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { navigateTo, useRouteParams } from '#imports'
import { watch } from 'vue'

import { getInsuranceDuePayment } from '@backmarket/http-api/src/api-specs-insurance/insurances/insurances'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import Layout from '~/scopes/insurance/components/Layout/Layout.vue'

import { INSURANCE } from '../../routes.constants'

import translations from './DueInsurance.translations'
import Checkout from './components/Checkout/Checkout.vue'
import DueSummary from './components/DueSummary/DueSummary.vue'

const { policyId } = useRouteParams()

const { data, error, pending } = useHttpFetch(getInsuranceDuePayment, {
  pathParams: { policyId },
})

const i18n = useI18n()

watch(error, () => {
  if (error.value?.statusCode === 400) {
    navigateTo(INSURANCE.DUE_PAYMENT_COMPLETED)
  }
})
</script>
